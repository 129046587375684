
@import '_colors.scss';

$primary-color: $at_yellow;
/*$font_family: 'Gotham', 'lato', sans-serif;*/
$font_family: 'lato', sans-serif;
$font_family-gothamM: 'Gotham-Medium', 'lato', sans-serif;
$font_family-gothamL: 'Gotham-Light', 'lato', sans-serif;
$font_family-gothamB: 'Gotham-Bold', 'lato', sans-serif;
$font_family-gothamT: 'Gotham-Thin', 'lato', sans-serif;
$font_family-gothamBook: 'Gotham-Book', 'lato', sans-serif;

.navbar-nav > li > a, .navbar-default .navbar-nav > li > a {
    font-weight: 600;
    font-size: 15px;
    
}

/*.navbar-nav > li.active a,
.header.blue .navbar-nav > li.active > a, .header.blue .navbar-default .navbar-nav > li.active > a {
    color: $primary-color;
    color: $primary-color;
}*/

.fixed-top.header.blue .navbar-default .navbar-nav > li.active > a,
.fixed-top.header.blue .navbar-default .navbar-nav > li > a:hover {
    color: $primary-color !important;
    text-shadow: none !important;
}

.fixed-top.header.blue .navbar-default .navbar-nav > li > a,
.header.blue .navbar-nav > li > a, .header.blue .navbar-default .navbar-nav > li > a {
    color: $blue !important;
}

.navbar-nav > li.active a,
.header.blue .navbar-nav > li.active > a,
.header.blue .navbar-default .navbar-nav > li.active > a {
    color: $primary-color !important;
}

.text-container-title h2, .header.blue .navbar-nav > li > a:hover,
.header.blue .navbar-default .navbar-nav > li > a:hover, .copyright a,
.e-blog-post-more:hover, .e-blog-post-title a:hover,
a:hover, a:focus, .f-widget-recent-entries li a:hover,
.header.blue .attr-nav .social-profile li a:hover,
.social-profile.blue .social-hex:hover, .e-blog-post-meta span > a:hover,
.widget ul > li > a:hover {
    color: $primary-color;
}

.text-container-title:before, .overlay-yellow:before,
.section-title:before, .scroll-top, .float-area1,
.client-caption, .post-navigation .post-nav-prev > i, .post-navigation .post-nav-next > i,
.e-blog-post-social-share-item {
    background: $primary-color;
}


.text-container-title h2, .c-blue {
    color: $blue !important;
}

.widget-title {
    color: $blue;
}

.float-area, .footer-bottom, .e-blog-post-social-share-item a:hover {
    background: $blue;
}

.float-area {
    background: $dark-grey;
    background: $primary-color;
}


.e-blog-post-more, .e-blog-post-title a, .e-blog-post-title,
.section-title h2, .our-feature-item h4, .text-container-title .light, a,
.agency-banner-content .btn, .title-main, .contact-title, .contact-box .title,
.header.blue .attr-nav .social-profile li a, .social-profile.blue .social-hex {
    color: $blue;
}

.overlay:before, .overlay-yellow:before {
    background: $blue;
    opacity: .2;
}

footer {
    .overlay:before {
        opacity: 0.85;
    }
}

.float-area-btn .btn:hover, .slider-p-area .btn:hover {
    background: $primary-color;
    border-color: $primary-color;
}

.float-area-btn .btn:hover {
    background: #fff;
    color: $primary-color;
    border-color: $primary-color;
}


.social-hex:hover, .social-profile.white .social-hex:hover {
    border-color: $primary-color;
    color: $primary-color;
}

.owl-thumb-item.active:before {
    border-color: $primary-color;
}

.style-2.yellow.portfolio-item:before, .style-3.yellow.portfolio-item:before,
.style-5.yellow.portfolio-item:before, .style-6.yellow.portfolio-item:before,
.style-7.yellow.portfolio-item:before {
    background: rgba(252, 185, 103, 0.85);
}

.style-2.blue.portfolio-item:before, .style-3.blue.portfolio-item:before,
.style-5.blue.portfolio-item:before, .style-6.blue.portfolio-item:before,
.style-7.portfolio-item:before {
    background: rgba(13, 17, 34, 0.65);
}

.btn {
    color: $primary-color;
    border-color: $primary-color;
}

.btn:hover, .btn.square:hover {
    color: #fff;
    background: $primary-color;
}

.service-item {
    &.blue {
        .title, .icon-only > i {
            color: $blue;
        }

        .hex {
            background: $blue;
        }
    }

    &.yellow {
        .title, .icon-only > i {
            color: $primary-color;
        }

        .hex {
            background: $primary-color;
        }
    }

    &.skyblue {
        .title, .icon-only > i {
            color: $light-grey;
        }

        .hex {
            background: $light-grey;
        }
    }

    &.green {
        .title, .icon-only > i {
            color: $wierd-grey;
        }

        .hex {
            background: $wierd-grey;
        }
    }

    &.wblue {
        .title, .icon-only > i {
            color: $dark-grey;
        }

        .hex {
            background: $dark-grey;
        }
    }
}

.service-item:hover {
    border-color: $blue;
}

.header-bottom {
    height: 95px;
    line-height: 95px;
}

.fixed-top .header-bottom {
    height: 80px;
    line-height: 80px;
}

body {
    font-family: $font_family-gothamBook;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font_family-gothamL;
    -webkit-font-smoothing: antialiased;

}

.navbar-nav > li > a, .navbar-default .navbar-nav > li > a {
    line-height: 95px;
    /*font-family: $font_family;*/
    font-weight: 400;
    font-family: $font_family-gothamM;
    letter-spacing: -0.4px;
}

.page-title-content h1 {
    font-size: 60px;
}

.agency-banner-content .btn:hover {
    background: $primary-color;
    border-color: $primary-color;
}

.contactform {
}

.extra-padding-top-header {
    padding: 50px 0;
}

.s-padding.text-and-img {
    padding-top: 0;

    .text-container-title {
        margin-top: 40px;
    }
}

.categorie-widget {
    .active a {
        color: $primary-color;
    }
}

.p-info-img {
    p img {
        max-width: 100%;
        height: auto !important;
    }
}

.post-nav-next {
    background: $blue;

    &:hover i {
        color: #fff;
    }
}

.file-upload {
    .fa {
        margin-left: 10px;

        &.fa-times {
            color: #fd1d06;
        }

        &.fa-check {
            color: #619316;
        }
    }
}


.our-feature-img {
    .lazy {
        background: none;
    }
}

.agency-banner-content h1 {
    font-weight: 400;
    font-family: $font_family-gothamBook;
}

.service-item p {
    color: $blue;
    font-weight: 400;
}

@media screen and (min-width: 996px) {
    .fixed-top .navbar-nav > li > a {
        line-height: 80px !important;
    }
}

@media screen and (min-width: 768px) {
    .services {
        .service:nth-child(3n+1) {
            clear: left;
        }
    }

    .blog-g-wrapper {
        .b-post-wrapper:nth-child(3n+1) {
            clear: left;
        }
    }

    .infinityselctor.blog-g-wrapper.col-3 > div:nth-child(3n+4) {
        clear: left;
    }

    .s-padding {
        padding: 90px 0;
    }

    .recent-project  {
        padding-top: 140px;
    }
}

@media screen and (min-width: 768px) {
    .product .product-preview {
        width: 45%;
    }

    .product-summary {
        width: 55%;
    }
}

@media screen and (max-width: 767px) {
    .contact.extra-padding-top-150, .recent-project.s-padding.extra-padding-top-150 {
        padding-top: 150px;
    }

    .portfolio-styles {
        padding-top: 0;
    }
}
