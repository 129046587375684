﻿

$at_yellow: #ffac00;
/*$primary-color: #ff5903;*/
/*$secondary-color: #201a16;*/

$blue: #0e1122;
$black: #000;
$dark-grey: #394959;
$light-grey: #5f7278;
$wierd-grey: #b7c4b0;
$wierd-blue: #79e6da;
$secondary-color: $blue;
$primary-color: $at_yellow;
 

