/*$primary-color: #ff5903;*/
/*$secondary-color: #201a16;*/
/*$font_family: 'Gotham', 'lato', sans-serif;*/
.navbar-nav > li > a, .navbar-default .navbar-nav > li > a {
  font-weight: 600;
  font-size: 15px; }

/*.navbar-nav > li.active a,
.header.blue .navbar-nav > li.active > a, .header.blue .navbar-default .navbar-nav > li.active > a {
    color: $primary-color;
    color: $primary-color;
}*/
.fixed-top.header.blue .navbar-default .navbar-nav > li.active > a,
.fixed-top.header.blue .navbar-default .navbar-nav > li > a:hover {
  color: #ffac00 !important;
  text-shadow: none !important; }

.fixed-top.header.blue .navbar-default .navbar-nav > li > a,
.header.blue .navbar-nav > li > a, .header.blue .navbar-default .navbar-nav > li > a {
  color: #0e1122 !important; }

.navbar-nav > li.active a,
.header.blue .navbar-nav > li.active > a,
.header.blue .navbar-default .navbar-nav > li.active > a {
  color: #ffac00 !important; }

.text-container-title h2, .header.blue .navbar-nav > li > a:hover,
.header.blue .navbar-default .navbar-nav > li > a:hover, .copyright a,
.e-blog-post-more:hover, .e-blog-post-title a:hover,
a:hover, a:focus, .f-widget-recent-entries li a:hover,
.header.blue .attr-nav .social-profile li a:hover,
.social-profile.blue .social-hex:hover, .e-blog-post-meta span > a:hover,
.widget ul > li > a:hover {
  color: #ffac00; }

.text-container-title:before, .overlay-yellow:before,
.section-title:before, .scroll-top, .float-area1,
.client-caption, .post-navigation .post-nav-prev > i, .post-navigation .post-nav-next > i,
.e-blog-post-social-share-item {
  background: #ffac00; }

.text-container-title h2, .c-blue {
  color: #0e1122 !important; }

.widget-title {
  color: #0e1122; }

.float-area, .footer-bottom, .e-blog-post-social-share-item a:hover {
  background: #0e1122; }

.float-area {
  background: #394959;
  background: #ffac00; }

.e-blog-post-more, .e-blog-post-title a, .e-blog-post-title,
.section-title h2, .our-feature-item h4, .text-container-title .light, a,
.agency-banner-content .btn, .title-main, .contact-title, .contact-box .title,
.header.blue .attr-nav .social-profile li a, .social-profile.blue .social-hex {
  color: #0e1122; }

.overlay:before, .overlay-yellow:before {
  background: #0e1122;
  opacity: .2; }

footer .overlay:before {
  opacity: 0.85; }

.float-area-btn .btn:hover, .slider-p-area .btn:hover {
  background: #ffac00;
  border-color: #ffac00; }

.float-area-btn .btn:hover {
  background: #fff;
  color: #ffac00;
  border-color: #ffac00; }

.social-hex:hover, .social-profile.white .social-hex:hover {
  border-color: #ffac00;
  color: #ffac00; }

.owl-thumb-item.active:before {
  border-color: #ffac00; }

.style-2.yellow.portfolio-item:before, .style-3.yellow.portfolio-item:before,
.style-5.yellow.portfolio-item:before, .style-6.yellow.portfolio-item:before,
.style-7.yellow.portfolio-item:before {
  background: rgba(252, 185, 103, 0.85); }

.style-2.blue.portfolio-item:before, .style-3.blue.portfolio-item:before,
.style-5.blue.portfolio-item:before, .style-6.blue.portfolio-item:before,
.style-7.portfolio-item:before {
  background: rgba(13, 17, 34, 0.65); }

.btn {
  color: #ffac00;
  border-color: #ffac00; }

.btn:hover, .btn.square:hover {
  color: #fff;
  background: #ffac00; }

.service-item.blue .title, .service-item.blue .icon-only > i {
  color: #0e1122; }

.service-item.blue .hex {
  background: #0e1122; }

.service-item.yellow .title, .service-item.yellow .icon-only > i {
  color: #ffac00; }

.service-item.yellow .hex {
  background: #ffac00; }

.service-item.skyblue .title, .service-item.skyblue .icon-only > i {
  color: #5f7278; }

.service-item.skyblue .hex {
  background: #5f7278; }

.service-item.green .title, .service-item.green .icon-only > i {
  color: #b7c4b0; }

.service-item.green .hex {
  background: #b7c4b0; }

.service-item.wblue .title, .service-item.wblue .icon-only > i {
  color: #394959; }

.service-item.wblue .hex {
  background: #394959; }

.service-item:hover {
  border-color: #0e1122; }

.header-bottom {
  height: 95px;
  line-height: 95px; }

.fixed-top .header-bottom {
  height: 80px;
  line-height: 80px; }

body {
  font-family: "Gotham-Book", "lato", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Gotham-Light", "lato", sans-serif;
  -webkit-font-smoothing: antialiased; }

.navbar-nav > li > a, .navbar-default .navbar-nav > li > a {
  line-height: 95px;
  /*font-family: $font_family;*/
  font-weight: 400;
  font-family: "Gotham-Medium", "lato", sans-serif;
  letter-spacing: -0.4px; }

.page-title-content h1 {
  font-size: 60px; }

.agency-banner-content .btn:hover {
  background: #ffac00;
  border-color: #ffac00; }

.extra-padding-top-header {
  padding: 50px 0; }

.s-padding.text-and-img {
  padding-top: 0; }
  .s-padding.text-and-img .text-container-title {
    margin-top: 40px; }

.categorie-widget .active a {
  color: #ffac00; }

.p-info-img p img {
  max-width: 100%;
  height: auto !important; }

.post-nav-next {
  background: #0e1122; }
  .post-nav-next:hover i {
    color: #fff; }

.file-upload .fa {
  margin-left: 10px; }
  .file-upload .fa.fa-times {
    color: #fd1d06; }
  .file-upload .fa.fa-check {
    color: #619316; }

.our-feature-img .lazy {
  background: none; }

.agency-banner-content h1 {
  font-weight: 400;
  font-family: "Gotham-Book", "lato", sans-serif; }

.service-item p {
  color: #0e1122;
  font-weight: 400; }

@media screen and (min-width: 996px) {
  .fixed-top .navbar-nav > li > a {
    line-height: 80px !important; } }

@media screen and (min-width: 768px) {
  .services .service:nth-child(3n+1) {
    clear: left; }
  .blog-g-wrapper .b-post-wrapper:nth-child(3n+1) {
    clear: left; }
  .infinityselctor.blog-g-wrapper.col-3 > div:nth-child(3n+4) {
    clear: left; }
  .s-padding {
    padding: 90px 0; }
  .recent-project {
    padding-top: 140px; } }

@media screen and (min-width: 768px) {
  .product .product-preview {
    width: 45%; }
  .product-summary {
    width: 55%; } }

@media screen and (max-width: 767px) {
  .contact.extra-padding-top-150, .recent-project.s-padding.extra-padding-top-150 {
    padding-top: 150px; }
  .portfolio-styles {
    padding-top: 0; } }
